import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
// import App from "./App";
import ReactGA from 'react-ga';
const App = lazy(() => import("./App"));

ReactGA.initialize('G-WM5JMHZVPT');

export const RootSpinner = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: "#01000b",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            width: "60px",
            height: "60px",
            border: "4px solid white",
            borderTopColor: "transparent",
            borderRadius: "50%",
            backgroundColor: "transparent",
            animation: `spin .75s linear infinite`,
          }}
        />
      </div>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<RootSpinner />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
